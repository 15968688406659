<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :date="date"
        :tip="tip"
        :link="link"
    >
        <section v-loading="loading">
            <el-tabs v-if="contentData.length > 0" tab-position="left">
                <el-tab-pane v-for="(item,itemIndex) in contentData" :key="itemIndex" :label="item.title">
                    <ul class="content">
                        <li v-for="(data,index) in item.datas" :key="index">
                            <el-link :underline="false" target="_blank">{{ data.distributorName }}</el-link>
                        </li>
                    </ul>
                </el-tab-pane>
            </el-tabs>
            <Empty v-else />
        </section>
    </CardComponent>
</template>

<script>

import CardComponent from '../components/card'
import Empty from '@/components/empty'
export default {
    name: '',
    components: { CardComponent, Empty },
    props: {
        title: {
            type: String,
            default: '本周新增分销商',
            required: true
        },
        tip: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'iconfont icon-benzhouxinzengfenxiaoshang'
        },
        contentData: {
            type: Array,
            default: () => ([])
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            lastMonday: '',
            lastSunday: '',
            date: ''
        }
    },
    created () {
    //
        const weekOfday = this.$moment().format('E')
        if (weekOfday === 1) {
            // 取上周日期
            this.lastMonday = this.$moment().subtract(7, 'days').format('YYYY.MM.DD')
            this.lastSunday = this.$moment().subtract(weekOfday, 'days').format('YYYY.MM.DD')
        } else {
            // 取本周日期
            this.lastMonday = this.$moment().subtract(weekOfday - 1, 'days').format('YYYY.MM.DD')
            this.lastSunday = this.$moment().add(7 - weekOfday, 'days').format('YYYY.MM.DD')
        }
        this.date = this.lastMonday + '-' + this.lastSunday
    },
    methods: {}
}
</script>
<style lang='less' scoped>
section {
    box-sizing: border-box;
    flex: 1;
    // padding: 0 30px 30px 30px;
    margin-top: 0.16rem;
    overflow: hidden;
    // background-color: yellow;
    /deep/ .el-tabs {
        box-sizing: border-box;
        display: flex;
        height: 100%;

        .el-tabs__content {
            flex: 1;
            padding-left: 0.3rem;
            overflow: hidden;

            .content {
                padding-right: 0.3rem;

                li {
                    height: 0.48rem;
                    margin-bottom: 0;
                    font-size: 0.16rem;
                    font-weight: 400;
                    line-height: 0.48rem;
                    color: #636a6f;

                    .el-link {
                        display: block;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                        .el-link--inner {
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            // letter-spacing: 0.1em;
                        }
                    }
                }
            }
        }

        .el-tabs__content:hover {
            overflow: auto;
        }
        // .el-tabs__nav:hover {
        //   overflow: auto;
        // }
        .el-tabs__nav-prev,
        .el-tabs__nav-next {
            display: none;
        }

        .el-tabs__nav {
            top: 50%;
            height: 100%;
            overflow: auto;
            transform: translateY(-50%) !important;

            &::-webkit-scrollbar {
                display: none;
            }

            .el-tabs__item.is-left {
                // padding: ;
                // box-sizing: content-box;
                // padding: .3rem 20px;
                height: 0.78rem;
                font-size: 0.18rem;
                line-height: 0.78rem;
            }

            .el-tabs__active-bar.is-left {
                width: 0.03rem;
                // transform: translateY(.3rem)!important;
                // top: .3rem;
                height: 0.4rem !important;
            }

            .el-tabs__item {
                min-width: 1.2rem;
                padding: 0 0.2rem;
                color: #606266;
                text-align: center;
            }

            .el-tabs__item.is-active {
                color: #409eff;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    //定义滑块 内阴影+圆角
    ::-webkit-scrollbar-thumb {
        background-color: rgba(50, 50, 50, 0.2);
        border-radius: 1em;
    }
    //定义滚动条轨道 内阴影+圆角
    ::-webkit-scrollbar-track {
        background-color: rgba(50, 50, 50, 0.1);
        border-radius: 1em;
    }

    /deep/ .el-tabs--left .el-tabs__active-bar.is-left {
        top: 0.19rem;
        // transform: translateY(50%);
    }
}
</style>
